import { DesignViewerLiteButtonGroups } from '../../DesignViewerLiteButtonGroups';
import { DesignViewerLiteDetachedContainer } from '../../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { GeneralReviewVisibilityControlsMobile } from './GeneralReviewVisibilityControlsMobile';
import { DraggableQCToolbar } from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const GeneralViewContainer = styled(Box)({
    width: 'max-content',
    minWidth: '50%',
    maxWidth: '100%',
});

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px 0',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
        padding: '16px 12px 0',
    },
}));

export const WaxupModelViewerAndControls: React.FC = ({ children }) => {
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const { appearance, setAppearance, modelRef, controlRef, zoom, order, refetch, selectedTab, setAnnotation } =
        useGuidedWaxupContext();

    return (
        <Container>
            {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && isMobileOrTablet && (
                <GeneralReviewVisibilityControlsMobile />
            )}
            <DesignViewerLiteButtonGroups />
            <DesignViewerLiteDetachedContainer
                order={order}
                refetchDesign={refetch}
                appearance={appearance}
                setAppearance={setAppearance}
                modelRef={modelRef}
                controlRef={controlRef}
                setPresetAnnotation={setAnnotation}
                camConfig={{ zoom }}
                style={isMobileOrTablet ? { height: '300px' } : { height: '500px', width: 'auto' }}
            >
                <DraggableQCToolbar dynamicHeatmaps appearance={appearance} setAppearance={setAppearance} hideZoom />
            </DesignViewerLiteDetachedContainer>
            {children}
        </Container>
    );
};
