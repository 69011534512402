import { isGuidedPreset } from '../GuidedWaxup.util';
import { MOBILE_HEIGHT } from '../VisualConstants.util';
import { HistoricalWaxupPresetInfo } from '../components';
import { GeneralRejectionNoteMobileDialog } from '../components/GeneralRejectionNoteMobileDialog';
import { HistoricalWaxupModelViewerAndControls } from '../components/HistoricalWaxupModelViewerControls';
import { MobileDoctorImagesDialog } from '../components/MobileDoctorImagesDialog';
import { MobileTimelineDialog } from '../components/MobileTimelineDialog';
import { RevisionHistoryTabs } from '../components/RevisionHistoryTabs';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useScrollToContainer } from '../state/GuidedWaxupState';
import type { HistoricalWaxupScreensProps } from './HistoricalWaxupDesktopScreen';
import { RootActionDialog } from '@orthly/ui';
import { Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: MOBILE_HEIGHT,
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'clip',
    },
    dialog: {
        alignContent: 'center',
        height: 200,
        textAlign: 'center',
    },
    bodyContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: MOBILE_HEIGHT,
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'clip',
        scrollBehavior: 'smooth',
    },
    revisionTabsContainer: {
        margin: '10px',
    },
}));

export const HistoricalWaxupMobileScreen: React.VFC<HistoricalWaxupScreensProps> = ({
    selectedDesignRevisionNotes,
    isApprovedWaxup,
}) => {
    const classes = useStyles();
    const {
        selectedDesignRevisionId,
        selectedTab,
        isMobileRejectionNoteOpen,
        enableGuidedWaxupComparison,
        isMobileTimelineDialogOpen,
        isMobileDoctorImagesDialogOpen,
    } = useGuidedWaxupContext();
    const [open, setOpen] = React.useState(true);
    const guidedPreset = isGuidedPreset(selectedTab);

    const scrollableRef = React.useRef<HTMLDivElement | null>(null);
    useScrollToContainer(scrollableRef);

    return (
        <Grid container className={classes.root}>
            {isMobileRejectionNoteOpen && (
                <GeneralRejectionNoteMobileDialog selectedRevisionNotes={selectedDesignRevisionNotes} />
            )}
            {isMobileTimelineDialogOpen && <MobileTimelineDialog />}
            {isMobileDoctorImagesDialogOpen && <MobileDoctorImagesDialog />}
            <RootActionDialog
                loading={false}
                open={!enableGuidedWaxupComparison && open}
                setOpen={setOpen}
                title={null}
                content={
                    <Grid container className={classes.dialog} onClick={() => setOpen(false)}>
                        <Text variant={'h6'} medium>
                            {isApprovedWaxup
                                ? 'This is your approved design.'
                                : `This is a previously rejected design.`}
                        </Text>
                    </Grid>
                }
                CustomButton={() => null}
                dialogProps={{ PaperProps: { style: { minWidth: 200, minHeight: 200, borderRadius: 16 } } }}
            />
            <div className={classes.bodyContainer} key={selectedDesignRevisionId} ref={scrollableRef}>
                {enableGuidedWaxupComparison && <RevisionHistoryTabs className={classes.revisionTabsContainer} />}
                <HistoricalWaxupModelViewerAndControls selectedDesignRevisionNotes={selectedDesignRevisionNotes} />
                {guidedPreset && <HistoricalWaxupPresetInfo />}
            </div>
        </Grid>
    );
};
