import { useCurrentProfile } from '../../../../../../redux';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlRemoveOrderHoldMutationVariables } from '@orthly/graphql-operations';
import { useRemoveOrderHoldMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type RemoveOrderHoldVars = LabsGqlRemoveOrderHoldMutationVariables['data'];

const isHoldAlreadyRemoved = (errorMessage: string) =>
    errorMessage.includes('Order is already resumed') || errorMessage.includes('Order hold is already removed');

export const useRemoveOrderHold = (refetch: () => void, orderId: string) => {
    const doctor = useCurrentProfile();

    const [submitRemoveHoldMtn] = useRemoveOrderHoldMutation();
    const removeHoldMtnSubmitter = (data: RemoveOrderHoldVars) => submitRemoveHoldMtn({ variables: { data } });

    return useChangeSubmissionFn<any, [RemoveOrderHoldVars]>(removeHoldMtnSubmitter, {
        successMessage: () => ['The order has successfully been resumed. No further action is required.'],
        onSuccess: async result => {
            /*
             * The first entry in the hold history is always the most recent hold.
             */
            const hold = result?.data?.removeOrderHold?.hold_history?.[0];

            if (hold) {
                BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Order Resumed', {
                    holdId: hold.hold_id,
                    doctorPref: doctor?.id ?? '',
                    $groups: { order: orderId },
                });
            }

            refetch();
        },
        errorMessage: e => [
            e.message,
            {
                submessage: isHoldAlreadyRemoved(e.message) ? 'Please refresh the page.' : '',
            },
        ],
    });
};
