import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { VeneerUseOrderRevisionItemsDesign_Fragment, getDesignRevisionLabel } from '../../OrderDesignActions.graphql';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import {
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    useScreenIsXs,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    revisionTabContainer: {
        textWrap: 'nowrap',
        overflow: 'auto',
        flexWrap: 'nowrap',
    },
    revisionTabs: {
        alignItems: 'center',
        padding: '4px 16px',
        cursor: 'pointer',
        ...GUIDED_WAXUP_COMMON_STYLES,
        '&:not(:last-child)': {
            borderRight: 'none',
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    selectedRevision: {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
    },
}));

export const RevisionHistoryTabs: React.VFC<{ className?: string }> = ({ className }) => {
    const { value: enableDDPComparison } = useFeatureFlag('enableDDPComparison');
    const classes = useStyles();
    const {
        order,
        designRevisionFragments,
        selectedTab,
        setIsIncompletedWorkReminderModalOpen,
        selectedDesignRevisionId,
        setSelectedDesignRevisionId,
        isAnnotatingScreenshot,
    } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const isXsScreen = useScreenIsXs();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const disableTabs =
        (presets[selectedTab]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected && !presets[selectedTab]?.notes) ||
        isAnnotatingScreenshot;

    const designRevisions = getFragmentData(VeneerUseOrderRevisionItemsDesign_Fragment, designRevisionFragments);
    const session = useSession();

    // We only want to display tabs if there are 2 or more design revisions
    // The new DDP comparison tool will not be supported on mobile screens
    if (designRevisions.length <= 1 || (enableDDPComparison && !isXsScreen)) {
        return null;
    }

    const sortedDesignRevisions = _.sortBy(designRevisions, revision => revision.created_at).reverse();
    const revisionMenuItems = sortedDesignRevisions.map((revision, idx) => {
        const selected = revision.id === selectedDesignRevisionId;
        const revisionLabel = getDesignRevisionLabel(
            revision,
            // We invert the numbers because the sortedDesignRevisions will be newest to oldest,
            // but the version #s are oldest to newest (ie the first version is always #1, the second is always #2, etc)
            sortedDesignRevisions.length - idx - 1,
            session?.organization_type === 'practice' ? 'version_idx' : 'type_and_date',
        );
        return {
            selected,
            onClick: () => {
                if (!disableTabs) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Guided Waxup - Previous Design Viewed', {
                        $groups: { order: order.id },
                        numberOfPreviousDesigns: designRevisions.filter(revision => !!revision.doctor_review).length,
                        previousDesignViewedLabel: revisionLabel,
                    });
                    setSelectedDesignRevisionId(revision.id);
                } else {
                    setIsIncompletedWorkReminderModalOpen(true);
                }
            },
            // If on mobile, we omit the date the version was created if its present
            // eslint-disable-next-line no-nested-ternary
            label: revision.is_latest_design
                ? `Current Design`
                : isMobile && revisionLabel.includes(':')
                  ? revisionLabel.substring(0, revisionLabel.indexOf(':'))
                  : revisionLabel,
        };
    });

    return (
        <Grid item className={className}>
            <Grid container className={classes.revisionTabContainer}>
                {revisionMenuItems.map(item => (
                    <Grid
                        key={item.label}
                        item
                        onClick={() => item.onClick()}
                        className={cx(classes.revisionTabs, item.selected && classes.selectedRevision)}
                    >
                        <Text variant={'body2'} medium color={item.selected ? 'WHITE' : 'PRIMARY_FOREGROUND'}>
                            {item.label}
                        </Text>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
