import type { IconName } from '@orthly/ui-primitives';
import { boldTitleFont, Button, FlossPalette, Icon, styled, Switch, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(Button)<{ checked: boolean }>(({ checked, theme }) => ({
    width: 'max-content',
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'nowrap',
    fontFamily: boldTitleFont,
    fontSize: '14px',
    lineHeight: '16px',
    '&&, &&:active, &&:focus, &&:hover': {
        backgroundColor: checked ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
        color: checked ? FlossPalette.PRIMARY_PRESSED : FlossPalette.PRIMARY_FOREGROUND,
    },
    '&&:active svg': {
        color: checked ? FlossPalette.PRIMARY_PRESSED : FlossPalette.PRIMARY_FOREGROUND,
    },
    [theme.breakpoints.down('lg')]: {
        height: '58px',
        flexDirection: 'column',
        gap: 0,
        fontSize: '12px',
        padding: '8px',
    },
}));

export const ToggleButton: React.VFC<{
    checked: boolean;
    compactLabel?: string;
    icon: IconName;
    label: string;
    onClick: () => void;
}> = ({ checked, icon, onClick, label, compactLabel = label }) => {
    const isLarge = useScreenIsLgOrUp();

    return (
        <StyledButton checked={checked} variant={'secondary'} size={'small'} onClick={onClick}>
            <Icon icon={icon} />
            {isLarge && <Switch color={'secondary'} checked={checked} sx={{ margin: 0 }} />}
            {isLarge ? label : compactLabel}
        </StyledButton>
    );
};
