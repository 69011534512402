import {
    Button,
    CloseIcon,
    ExpandMoreIcon,
    FlossPalette,
    Grid,
    styled,
    Text,
    useScreenIsLgOrUp,
} from '@orthly/ui-primitives';
import React from 'react';

interface DesignViewLiteButtonProps {
    icon: React.ReactNode;
    id?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    label: string;
    extraLabel?: string;
    isActive: boolean;
}

const LabelIconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
});

const LabelWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: 4,
});

const IconWrapper = styled('div')({
    display: 'flex',
});

const ButtonsContainer = styled(Grid)(({ theme }) => ({
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: 0,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        height: '58px',
        minWidth: 'max-content',
        padding: '8px',
    },
}));

export const DesignViewLiteButton: React.VFC<DesignViewLiteButtonProps> = ({
    icon,
    id,
    label,
    extraLabel,
    onClick,
    onClose,
    isActive,
}) => {
    const isScreenLgOrUp = useScreenIsLgOrUp();
    const IconEl = extraLabel ? <CloseIcon onClick={onClose} /> : <ExpandMoreIcon />;
    const isSelected = isActive || extraLabel;

    return (
        <StyledButton
            variant={'secondary'}
            size={'small'}
            id={id}
            onClick={onClick}
            sx={
                isSelected
                    ? {
                          '&&, &&:active, &&:focus, &&:hover': {
                              backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
                          },
                      }
                    : {}
            }
        >
            <ButtonsContainer container>
                {icon}
                {!isScreenLgOrUp ? (
                    <LabelIconContainer>
                        <LabelWrapper>
                            <Text
                                variant={'caption'}
                                medium
                                color={'PRIMARY_FOREGROUND'}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {extraLabel ? `${label}: ${extraLabel}` : label}
                            </Text>
                        </LabelWrapper>
                        <IconWrapper>{IconEl}</IconWrapper>
                    </LabelIconContainer>
                ) : (
                    <>
                        {extraLabel ? `${label}: ${extraLabel}` : label}
                        {IconEl}
                    </>
                )}
            </ButtonsContainer>
        </StyledButton>
    );
};
