import type { BigRadioOption, BigRadioSelectRowProps } from '../../CheckoutFormFields/BigRadioSelect';
import type { RecommendedMaterial } from './recommended-materials';
import {
    type ICartItemV2DTO,
    type ICartBridgeItem,
    type ToothNumber,
    CartItemV2Utils,
    LabOrderItemSKUType,
    OrderItemLinkRelationship,
    ToothUtils,
} from '@orthly/items';
import _ from 'lodash';
import React from 'react';

interface SelectOption {
    value: string;
    label: string;
    helpText?: string | JSX.Element;
}

const genOptions = (input: (string | SelectOption)[]): SelectOption[] =>
    input.map(i => (typeof i === 'string' ? { value: i, label: i } : i));

const PFM_MATERIALS = genOptions([
    {
        value: 'PFM - High Noble - Yellow',
        label: 'PFM - High Noble - Yellow',
        helpText: 'Au= 73%, 18K',
    },
    {
        value: 'PFM - High Noble - White',
        label: 'PFM - High Noble - White',
        helpText: 'Au= 40%, 10K',
    },
    {
        value: 'PFM - Noble - White',
        label: 'PFM - Noble - White',
        helpText: 'Pt= 20%',
    },
    {
        value: 'PFM - Non-Precious',
        label: 'PFM - Non-Precious',
        helpText: 'Economical metal option.',
    },
]);

const EMAX_MATERIALS = genOptions(['Emax Full Contour', 'Emax Layered Porcelain']);

const GOLD_FULL_CAST_METAL_MATERIALS = genOptions([
    {
        value: 'High Noble - Rich Yellow',
        label: 'High Noble - Rich Yellow',
        helpText: (
            <>
                Full cast alloy with a rich yellow color which offers outstanding bio-compatibility.
                <br />
                <br />
                Au= 58%, 14K
            </>
        ),
    },
    {
        value: 'Noble - Yellow',
        label: 'Noble - Yellow',
        helpText: (
            <>
                Full cast alloy with a warm yellow color and multipurpose applications.
                <br />
                <br />
                Au= 40%, 10K
            </>
        ),
    },
    {
        value: 'Noble - White',
        label: 'Noble - White',
        helpText: (
            <>
                Silver palladium alloy with excellent strength and bio-compatibility.
                <br />
                <br />
                Pd= 24.97% Ag= 70%
            </>
        ),
    },
    {
        value: 'Non-Precious',
        label: 'Non-Precious',
        helpText: 'Economical metal option.',
    },
]);

const OTHER_MATERIALS = genOptions([{ label: 'PMMA', value: 'Temporary' }]);

const areZirconiaMonoliticAndPFZDisabled = (item: ICartItemV2DTO) => {
    if (
        !CartItemV2Utils.itemTypeHasImplant(item) ||
        !CartItemV2Utils.getAllMaterials(item).includes('Custom Zirconia Hybrid Abutment')
    ) {
        return false;
    }

    const relationship = CartItemV2Utils.getImplantMetadata(item)?.relationship;
    if (relationship === OrderItemLinkRelationship.ScrewRetained) {
        return true;
    }

    return (
        ToothUtils.isAnterior(CartItemV2Utils.getUniqueUNNs(item)) &&
        (relationship === OrderItemLinkRelationship.CementRetained ||
            relationship === OrderItemLinkRelationship.Screwmentable)
    );
};

const isZirconiaTranslucentAestheticDisabled = (item: ICartItemV2DTO): boolean => {
    const isBridge = CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge);
    const isImplantBridge = CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge);

    if (!isBridge && !isImplantBridge) {
        return false;
    }

    if (CartItemV2Utils.getUniqueUNNs(item).length > 5) {
        return true;
    }

    const ponticsOnly = isBridge
        ? {
              ...item,
              units: item.units.filter(u => u.unit_type === 'CrownPontic'),
          }
        : {
              ...item,
              implants: [],
              restoratives: item.restoratives.filter(r => r.unit_type === 'CrownPontic'),
          };

    const adjacentPonticGroups = CartItemV2Utils.getAdjacentTeethGroups([ponticsOnly]);

    // Disabled if there are any adjacent pontics.
    return adjacentPonticGroups.some(group => group.length > 1);
};

export const isCrownUnderPartial = (item: ICartItemV2DTO): boolean => {
    if (!CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown)) {
        return false;
    }
    return item.preference_fields.some(({ field_id, value }) => field_id === 'crown-under-partial' && value === 'Yes');
};

export const getMainOptions = (
    item: ICartItemV2DTO,
    unns: ToothNumber[],
    recommendedMaterial?: RecommendedMaterial,
): BigRadioOption<string>[] => {
    if (recommendedMaterial?.value === 'PFM - High Noble - Yellow') {
        return PFM_MATERIALS.slice(0, 2);
    }

    const disableZirconiaTranslucentAesthetic = isZirconiaTranslucentAestheticDisabled(item);
    const zirconiaDisabled = areZirconiaMonoliticAndPFZDisabled(item);
    const zirconiaDisabledMessage =
        "Change the abutment material from Custom Zirconia Hybrid to Ti-Base, Custom Titanium or Lab's choice to unlock this option";

    const itemIsInlayOrVeneer = CartItemV2Utils.itemIsType(item, [
        LabOrderItemSKUType.Inlay,
        LabOrderItemSKUType.Veneer,
    ]);

    const isCuP = isCrownUnderPartial(item);
    const isCrownOnSmileLine =
        CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown) &&
        unns.some(unn => ToothUtils.toothIsSmileLine(unn));

    const mainOptions: BigRadioOption<string>[] = [
        ...(isCuP || isCrownOnSmileLine || itemIsInlayOrVeneer ? genOptions(['Emax Full Contour']) : []),
        {
            label: 'Zirconia Monolithic',
            value: 'Zirconia Monolithic',
            disabled: zirconiaDisabled,
            body: zirconiaDisabled ? 'Incompatible with your current abutment material selection' : undefined,
            helpText: zirconiaDisabled ? zirconiaDisabledMessage : undefined,
        },
        {
            label: 'Zirconia Translucent Aesthetic',
            value: 'Zirconia Translucent Aesthetic',
            disabled: disableZirconiaTranslucentAesthetic,
            body: disableZirconiaTranslucentAesthetic
                ? 'Incompatible with Bridges containing adjacent pontics or more than five units'
                : undefined,
        },
    ];

    if (!isCuP) {
        mainOptions.push({
            label: 'Porcelain Fused Zirconia (PFZ, Layered)',
            value: 'Porcelain Fused Zirconia',
            disabled: zirconiaDisabled,
            body: zirconiaDisabled ? 'Incompatible with your current abutment material selection' : undefined,
            helpText: zirconiaDisabled ? zirconiaDisabledMessage : undefined,
        });
    }

    return mainOptions;
};
// 'Emax Layered Porcelain' is disabled for teeth 1-3, 14-16, 17-19, 30-32 (posterior 3 teeth in each quadrant)
const emaxLayeredPorcelainDisabledTeeth: readonly ToothNumber[] = [1, 2, 3, 14, 15, 16, 17, 18, 19, 30, 31, 32];
const getEmaxOptions = (item: ICartItemV2DTO, unns: ToothNumber[]): SelectOption[] => {
    const isCuP = isCrownUnderPartial(item);

    const disableEmaxLayeredPorcelain = unns.some(unn => emaxLayeredPorcelainDisabledTeeth.includes(unn)) || isCuP;

    if (!disableEmaxLayeredPorcelain) {
        return EMAX_MATERIALS;
    }

    return EMAX_MATERIALS.filter(o => {
        if (o.value === 'Emax Layered Porcelain' && disableEmaxLayeredPorcelain) {
            return false;
        }
        return true;
    });
};

export function generateOptions(item: ICartItemV2DTO, unns: ToothNumber[], recommendedMaterial?: RecommendedMaterial) {
    const mainOptions = getMainOptions(item, unns, recommendedMaterial);
    const pfmCategory = getPfmCategory(item);
    const emaxCategory = getEmaxCategory(item, unns);

    return {
        mainOptions,
        moreOptions: _.compact([
            pfmCategory,
            emaxCategory,
            {
                title: 'Gold / Full Cast Metal',
                options: GOLD_FULL_CAST_METAL_MATERIALS,
            },
            {
                title: 'Other',
                options: OTHER_MATERIALS,
            },
        ]).map(category => stripDuplicates(mainOptions, category)),
    };
}

function stripDuplicates(
    mainOptions: BigRadioOption<string>[],
    category: BigRadioSelectRowProps<string>,
): BigRadioSelectRowProps<string> | undefined {
    const mainOptionValues = mainOptions.map(o => o.value);
    // If an option exists in the main area then we do not need to duplicate it under any of the categories.
    const optionsWithoutDuplicates = category.options.filter(o => !mainOptionValues.includes(o.value));
    if (!optionsWithoutDuplicates.length) {
        // We can remove the category altogether if it doesn't have any options.
        return undefined;
    }
    return {
        ...category,
        options: category.options.filter(o => !mainOptionValues.includes(o.value)),
    };
}

function getPfmCategory(item: ICartItemV2DTO): BigRadioSelectRowProps<string> | undefined {
    if (isCrownUnderPartial(item)) {
        return undefined;
    }

    const { disabled, helpText } = checkDisabledForTiBaseImplant(item);

    return {
        title: 'Porcelain Fused to Metal (PFM)',
        disabled,
        helpText,
        // We don't support PFM - Non-precious for implant cases
        options: CartItemV2Utils.itemTypeHasImplant(item)
            ? PFM_MATERIALS.filter(o => o.value !== 'PFM - Non-Precious')
            : PFM_MATERIALS,
    };
}

function checkDisabledForTiBaseImplant(item: ICartItemV2DTO): { disabled: boolean; helpText?: string } {
    if (CartItemV2Utils.itemTypeHasImplant(item) && CartItemV2Utils.getAllMaterials(item).includes('Ti-Base')) {
        return {
            disabled: true,
            helpText:
                "Change the abutment material from Ti-Base to Lab's Choice, Custom Titanium or Custom Zirconia Hybrid to unlock these options.",
        };
    }

    return { disabled: false };
}

function getEmaxCategory(item: ICartItemV2DTO, unns: ToothNumber[]): BigRadioSelectRowProps<string> | undefined {
    const options = getEmaxOptions(item, unns);

    if (!options.length) {
        return undefined;
    }

    const { disabled, helpText } = checkEmaxCategoryDisabled(item);

    return {
        title: 'IPS E.Max',
        disabled,
        helpText,
        options,
    };
}

function checkEmaxCategoryDisabled(item: ICartItemV2DTO): { disabled: boolean; helpText?: string } {
    const tiBaseDisabledCheck = checkDisabledForTiBaseImplant(item);
    if (tiBaseDisabledCheck.disabled) {
        return tiBaseDisabledCheck;
    }

    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge) && isEmaxInvalidForBridge(item)) {
        return {
            disabled: true,
            helpText: 'Only available for bridges of up to 3 units on the lower anterior teeth (22-27)',
        };
    }

    return { disabled: false };
}

function isEmaxInvalidForBridge(item: ICartBridgeItem): boolean {
    // Emax options are disabled for all bridges, except if it’s only 1 pontic and teeth 22-27.

    return !(
        item.units.filter(u => u.unit_type === 'CrownPontic').length === 1 &&
        item.units.every(u => u.unn >= 22 && u.unn <= 27)
    );
}
