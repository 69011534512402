import { useGuidedWaxupContext } from '../GuidedWaxups/state/GuidedWaxupContext';
import type { QcHeatmapRange } from '@orthly/dentin';
import { QCColorLegend } from '@orthly/dentin';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const useQCColorLegend = () => {
    const { setAppearance } = useGuidedWaxupContext();

    const onChange = React.useCallback(
        (heatMapRange: QcHeatmapRange) => {
            setAppearance(current => ({ ...current, heatMapRange }));
        },
        [setAppearance],
    );

    return { onChange };
};

export const QCColorLegendContainer: React.VFC<{ isHeatmapView: boolean }> = ({ isHeatmapView }) => {
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const { appearance } = useGuidedWaxupContext();
    const { onChange } = useQCColorLegend();

    if (!isMobileOrTablet || !isHeatmapView) {
        return null;
    }

    return (
        <QCColorLegend
            dynamicHeatmaps
            heatMapType={appearance.activeHeatMap}
            heatMapRange={appearance.heatMapRange}
            horizontal
            setHeatMapRange={onChange}
            hideZoom
        />
    );
};
