import { BigRadioSelect } from '../../CheckoutFormFields/BigRadioSelect';
import { generateOptions } from './item-crown-material-options';
import { getRecommendedMaterial, moveRecommendedValueToTop } from './recommended-materials';
import type { ICartItemV2DTO, ToothNumber } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

/**
 * NOTE: this file was originally created in the `Scanner` repo.
 * The extended history can be found here:
 * https://github.com/orthly/Scanner/commits/41f6bc0c748793c46b51fda85890b0806528128d/packages/fluoride/src/components/case-builder/setup/items/steps/ItemCrownMaterialSelect.tsx
 */

export const ItemCrownMaterialSelect: React.VFC<{
    // Current material
    material?: string;
    // Called when the selection changes.
    onChange: (material: string) => void;
    // The item being edited, or in the case of a group edit, a representative item from the group.
    item: ICartItemV2DTO;
    // All unns involved in the material selection.
    unns: ToothNumber[];
    // Are recommended materials enabled?
    recommendationsEnabled?: boolean;
}> = ({ material, onChange, item, unns, recommendationsEnabled }) => {
    const recommendedMaterial = getRecommendedMaterial(item, unns, recommendationsEnabled);
    const { mainOptions, moreOptions } = generateOptions(item, unns, recommendedMaterial);

    if (recommendedMaterial) {
        moveRecommendedValueToTop(mainOptions, recommendedMaterial.value);
    }

    return (
        <BigRadioSelect
            value={material}
            onChange={onChange}
            options={mainOptions}
            recommendation={recommendedMaterial?.value}
            moreOptions={_.compact(moreOptions)}
            moreOptionsLabel={'PFM, E.Max, Gold, PMMA and Metal'}
        />
    );
};
