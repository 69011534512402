import type { BigRadioOption } from '../../CheckoutFormFields';
import { isCrownUnderPartial, getMainOptions } from './item-crown-material-options';
import {
    type ICartItemV2DTO,
    type ToothNumber,
    LabOrderItemSKUType,
    ToothUtils,
    type ICartBridgeItem,
} from '@orthly/items';

export interface RecommendedMaterial {
    value: string;
    tip?: {
        headline: string;
        body: string;
    };
}

export function getRecommendedMaterial(
    item: ICartItemV2DTO,
    unns: ToothNumber[],
    recommendationsEnabled: boolean = false,
): RecommendedMaterial | undefined {
    switch (item.sku) {
        case LabOrderItemSKUType.Inlay:
        case LabOrderItemSKUType.Veneer:
            return { value: 'Emax Full Contour' };
        case LabOrderItemSKUType.Crown:
            return getRecommendedCrownMaterial(unns, isCrownUnderPartial(item), recommendationsEnabled);
        case LabOrderItemSKUType.Bridge:
            const recommendedMaterial = getRecommendedBridgeMaterial(item, unns, recommendationsEnabled);

            if (recommendedMaterial) {
                const options = getMainOptions(item, unns, recommendedMaterial);

                if (options.some(option => option.disabled)) {
                    return { ...recommendedMaterial, tip: undefined };
                }
            }

            return recommendedMaterial;
    }
}

function getRecommendedCrownMaterial(
    unns: ToothNumber[],
    isCrownUnderPartial: boolean,
    recommendationsEnabled: boolean,
): RecommendedMaterial | undefined {
    if (!recommendationsEnabled) {
        return;
    }

    const isMolar = unns.some(unn => ToothUtils.toothIsMolar(unn));
    const isSmileLine = unns.some(unn => ToothUtils.toothIsSmileLine(unn));

    if (isMolar) {
        return {
            value: 'Zirconia Monolithic',
            tip: {
                headline: 'Each crown and patient are unique',
                body:
                    'While we recommend Zirconia Monolithic, Zirconia Translucent Aesthetic is an available ' +
                    'option if aesthetics are a priority and occlusal load is moderate. Please note that Zirconia ' +
                    'Translucent Aesthetic is less durable against heavier occlusal forces and bruxism.',
            },
        };
    }

    if (isSmileLine && isCrownUnderPartial) {
        return {
            value: 'Zirconia Translucent Aesthetic',
            tip: {
                headline: 'Stump shade influences material shade',
                body:
                    'For anterior cases, be mindful that stump shade can heavily influence the restoration’s shade. ' +
                    'Darker stumps may show through due to material translucency, impacting aesthetic outcomes.',
            },
        };
    }

    if (isSmileLine) {
        return {
            value: 'Emax Full Contour',
            tip: {
                headline: 'Stump shade influences material shade',
                body:
                    'For anterior cases, be mindful that stump shade can heavily influence the restoration’s shade. ' +
                    'Darker stumps may show through due to material translucency, impacting aesthetic outcomes.',
            },
        };
    }
}

/**
 * Returns true if a list of unns contains multiple bridges. For example
 * [1,2,3,6,7,8] contains two bridges (1-3 and 6-8). Also, a list of unns where
 * the teeth are split between upper and lower arches has multiple bridges e.g
 * [14,15,16,17,18,19] contains two bridges (14-16: upper) and (17-19: lower)
 *
 * @param {number[]} unns
 * @returns {boolean}
 */
function hasMultipleBridges(unns: number[]): boolean {
    if (!(ToothUtils.isUpper(unns) || ToothUtils.isLower(unns))) {
        return true;
    }

    const sortedUnns = [...unns].sort((unnA, unnB) => unnA - unnB);
    let expectedUnn = sortedUnns[0];

    for (const unn of sortedUnns) {
        if (unn !== expectedUnn) {
            return true;
        }

        expectedUnn += 1;
    }

    return false;
}

/**
 * Bridge material recommendations depend on whether the pontic is greater than or less than
 * 24mm (based on CST bridge material logic). Given the average mesio-distal measurements of teeth
 * in the mouth, the total number of pontics can be used to guide this logic and ensure material
 * recommendations are accommodating to this 24mm:
 *
 * 1 or 2 pontic bridges: We are always able to recommend materials for bridges with 1 or 2 pontics,
 *   as there is no two-consecutive-tooth span that exceeds the 24mm barometer.
 * 3 pontic bridges: We should not make recommendations for 3-pontic bridges because this is where the
 *   span could see variation in mm.
 * 4+ pontic bridges: We are always able to recommend materials for bridges with 4+ pontics, as they
 *   will always exceed the 24mm barometer.
 *
 * @param {ICartBridgeItem} item
 * @param {ToothNumber[]} unns
 * @param {boolean} recommendationsEnabled
 * @returns {RecommendedMaterial | undefined}
 */
function getRecommendedBridgeMaterial(
    item: ICartBridgeItem,
    unns: ToothNumber[],
    recommendationsEnabled: boolean,
): RecommendedMaterial | undefined {
    if (!recommendationsEnabled) {
        return;
    }

    const numUnits = item.units.length;
    const numPontics = item.units.filter(unit => unit.unit_type === 'CrownPontic').length;

    if (!numPontics || hasMultipleBridges(unns)) {
        return;
    }

    const isMolarInclusiveBridge = unns.some(ToothUtils.toothIsMolar);
    const isAnteriorBridge = unns.every(ToothUtils.toothIsAnterior);
    const isAnteriorPremolarBridge = unns.every(
        unn => ToothUtils.toothIsAnterior(unn) || ToothUtils.toothIsBicuspid(unn),
    );

    if (isAnteriorBridge && numUnits === 3 && numPontics === 1) {
        return {
            value: 'Porcelain Fused Zirconia',
            tip: {
                headline: 'Each bridge and patient are unique',
                body:
                    'We recommend PFZ for its realistic aesthetic outcomes combined with strength and durability ' +
                    'in the aesthetic zone. Alternatively, Emax Full Contour is available, but it provides less ' +
                    'durability, particularly for patients with heavy occlusal forces or a history of bruxism.',
            },
        };
    }

    if (isAnteriorPremolarBridge && numUnits <= 3 && numPontics === 1) {
        return {
            value: 'Porcelain Fused Zirconia',
            tip: {
                headline: 'Each bridge and patient are unique',
                body:
                    'We recommend PFZ for its realistic aesthetic results combined with strength and durability ' +
                    'in the aesthetic zone. Alternatively, Zirconia Translucent Aesthetic is available, though it is ' +
                    'less aesthetic, particularly for patients with heavy occlusal forces or a history of bruxism.',
            },
        };
    }

    if (isAnteriorPremolarBridge && numUnits === 4 && numPontics === 2) {
        return {
            value: 'Porcelain Fused Zirconia',
            tip: {
                headline: 'Each bridge and patient are unique',
                body:
                    'We recommend PFZ for its realistic aesthetic outcomes combined with strength and durability ' +
                    'in the aesthetic zone.',
            },
        };
    }

    if ((isAnteriorPremolarBridge && numUnits >= 6 && numPontics >= 4) || (isMolarInclusiveBridge && numPontics >= 4)) {
        return {
            value: 'PFM - High Noble - Yellow',
            tip: {
                headline: 'High Noble materials influence restoration shade',
                body:
                    'We recommend PFM - High Noble materials for their balance of aesthetics and durability. ' +
                    'PFM - High Noble - Yellow is recommended for cases requiring warmer, natural tooth shades. ' +
                    'PFM - High Noble - White is recommended for cases requiring cooler, neutral tooth shades',
            },
        };
    }

    if (isMolarInclusiveBridge && numPontics <= 2) {
        return {
            value: 'Zirconia Monolithic',
            tip: {
                headline: 'Each bridge and patient are unique',
                body:
                    'While we recommend Zirconia Monolithic, PFZ is a better option when aesthetics take priority ' +
                    'over strength. However, PFZ is less durable against occlusal forces.',
            },
        };
    }
}

export function moveRecommendedValueToTop(mainOptions: BigRadioOption<string>[], recommendedValue?: string) {
    const recommendedOptionIndex = recommendedValue
        ? mainOptions.findIndex(option => option.value === recommendedValue)
        : -1;

    if (recommendedOptionIndex === -1) {
        return;
    }

    const recommendedOption = mainOptions[recommendedOptionIndex] as BigRadioOption<string>;
    mainOptions.splice(recommendedOptionIndex, 1);
    mainOptions.unshift(recommendedOption);
}
