import { OrderAction, OrderActionTrackerIdMap } from '../../OrderActionsUtils';
import type { PauseOrResumeOrderActionProps } from './PauseOrResumeOrderAction.types';
import { useRemoveOrderHold } from './hooks/useRemoveOrderHold';
import { useOrderRefetch } from '@orthly/graphql-react';
import { PauseOrderActionReason } from '@orthly/shared-types';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

type ResumeOrderActionProps = Omit<PauseOrResumeOrderActionProps, 'preselectedOption' | 'refetch'>;

export const ResumeOrderAction: React.VFC<ResumeOrderActionProps> = ({ order, buttonVariant, leftAlign }) => {
    const holdIsDDPHold = order.hold_details?.hold_subcategory === PauseOrderActionReason.ReviewDesign;

    const refetch = useOrderRefetch();

    const { submit: submitRemoveHold } = useRemoveOrderHold(() => refetch(order.id), order.id);

    const onSubmit = () => {
        void submitRemoveHold({ orderId: order.id, holdId: order.hold_details?.hold_id });
    };

    return (
        <Button
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.ResumeOrder]}
            data-test={'resume-order-button'}
            leftAlign={leftAlign}
            variant={buttonVariant}
            startIcon={'PlayArrowIcon'}
            onClick={onSubmit}
        >
            Resume order{holdIsDDPHold ? ' to view design' : ''}
        </Button>
    );
};
