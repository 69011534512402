import { isGuidedPreset } from '../GuidedWaxup.util';
import { MOBILE_HEIGHT } from '../VisualConstants.util';
import { CurrentWaxupModelViewerAndControls } from '../components/CurrentWaxupModelViewerControls';
import { GeneralRejectionNoteMobileDialog } from '../components/GeneralRejectionNoteMobileDialog';
import { GuidedPresetControls } from '../components/GuidedPresetControls';
import { IncompletedWorkReminderModal } from '../components/IncompletedWorkReminderModal';
import { MobileDoctorImagesDialog } from '../components/MobileDoctorImagesDialog';
import { MobileTimelineDialog } from '../components/MobileTimelineDialog';
import { RevisionHistoryTabs } from '../components/RevisionHistoryTabs';
import { SkipToGeneralReviewModal } from '../components/SkipToGeneralReviewModal';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useScrollToContainer } from '../state/GuidedWaxupState';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: MOBILE_HEIGHT,
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'clip',
    },
    modelViewerContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: MOBILE_HEIGHT,
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'clip',
        scrollBehavior: 'smooth',
    },
    toggleContainer: {
        padding: '5px 10px 10px 10px',
        overflow: 'auto',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
    },
    revisionTabsContainer: {
        margin: '10px',
    },
}));

export const CurrentWaxupMobileScreen: React.VFC = () => {
    const classes = useStyles();
    const {
        selectedDesignRevisionId,
        selectedTab,
        isMobileTimelineDialogOpen,
        isMobileDoctorImagesDialogOpen,
        isMobileRejectionNoteOpen,
        enableGuidedWaxupComparison,
    } = useGuidedWaxupContext();
    const guidedPreset = isGuidedPreset(selectedTab);
    const scrollableRef = React.useRef<HTMLDivElement | null>(null);
    useScrollToContainer(scrollableRef);

    return (
        <Grid container className={classes.root}>
            <SkipToGeneralReviewModal />
            <IncompletedWorkReminderModal />
            {isMobileRejectionNoteOpen && <GeneralRejectionNoteMobileDialog />}
            {isMobileTimelineDialogOpen && <MobileTimelineDialog />}
            {isMobileDoctorImagesDialogOpen && <MobileDoctorImagesDialog />}
            <div className={classes.modelViewerContainer} key={selectedDesignRevisionId} ref={scrollableRef}>
                {enableGuidedWaxupComparison && <RevisionHistoryTabs className={classes.revisionTabsContainer} />}
                <CurrentWaxupModelViewerAndControls />
                <Grid
                    item
                    container
                    direction={'row'}
                    justifyContent={guidedPreset ? 'space-between' : 'flex-end'}
                    key={selectedTab}
                >
                    {guidedPreset && <GuidedPresetControls />}
                </Grid>
            </div>
        </Grid>
    );
};
