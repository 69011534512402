import { useTrackingDetails } from './useTrackingDetails';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrders } from '@orthly/graphql-react';
//import { UuidUtils } from '@orthly/runtime-utils';
import { useDoctorRequestHoldInfo } from '@orthly/veneer';
import constate from 'constate';

const OrderDetails_Query = graphql(`
    query OrderDetails_Query($order_id: String!, $timezone_offset_minutes: Int!) {
        getReview(lab_order_id: $order_id) {
            id
        }
        holdTitle: getOrderTrackingHoldTitle(id: $order_id)
        holdMessage: getOrderTrackingHoldMessage(id: $order_id)
        pendingReturnForOrder(order_id: $order_id) {
            status
            label_url
        }
        getActiveScanReviewByLabOrderId(lab_order_id: $order_id) {
            ...PracticeGetAskDoctorV2ConfigurationScanReview_Fragment
        }
        getOrderTrackerEntries(order_id: $order_id, timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            subtitle
            subtitleAttachment
            title
            infoTooltip
            style
        }
    }
`);
// the rule has issues with this export format
// eslint-disable-next-line import/no-unused-modules
export const [OrderDetailsContextProvider, useOrderDetailsContext] = constate(
    ({ order, refetch }: { order: LabsGqlOrder; refetch: () => Promise<void> }) => {
        const {
            data: {
                getReview,
                holdTitle,
                holdMessage,
                pendingReturnForOrder,
                getActiveScanReviewByLabOrderId,
                getOrderTrackerEntries,
            } = {},
            loading: isOrderDetailLoading,
        } = useQuery(OrderDetails_Query, {
            variables: {
                order_id: order?.id ?? '',
                timezone_offset_minutes: new Date().getTimezoneOffset(),
            },
            // this is a hack to make the cypress test continue to pass because it relies on the order id being '1'
            // ideally we would skip this query if not a uuid but the tests are broken hard with an import error on this line
            //skip: !UuidUtils.isUUID(order?.id) && order?.id !== '1',
        });

        const doctorRequestHoldInfo = useDoctorRequestHoldInfo(order);

        const { orders: ordersMatchingPartnerOrderId } = useOrders(
            { filter: { partner_order_id: order.partner_order_id } },
            { skip: !order.split_order_count },
        );
        const otherOrders = ordersMatchingPartnerOrderId.filter(o => o.id !== order.id);

        const {
            needsAttention = false,
            onPause = false,
            subtitle,
            title,
            titleButton,
            titleDate,
            titleSuffix,
        } = useTrackingDetails(order, otherOrders, holdTitle, pendingReturnForOrder?.status, doctorRequestHoldInfo) ??
        {};

        return {
            isDataLoading: doctorRequestHoldInfo.isDoctorRequestLoading || isOrderDetailLoading,
            needsAdditionalScans: doctorRequestHoldInfo.needsAdditionalScans,
            existingReview: !!getReview,
            activeScanReviewFragment: getActiveScanReviewByLabOrderId,
            orderTrackerEntries: getOrderTrackerEntries ?? [],
            holdMessage,
            needsAttention,
            onPause,
            order,
            pendingReturnForOrder,
            refetch,
            subtitle,
            title,
            titleButton,
            titleDate,
            titleSuffix,
        };
    },
);
